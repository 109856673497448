<template>
  <div class="no-informacion">
      <h1>Lo sentimos, estamos cargando la información...</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style>



</style>